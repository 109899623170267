.inactive {
    fill: #4c5a7d;
}

.active {
    animation: pulse 1.5s linear infinite;
}

@keyframes pulse {
    0% {
        fill: #0085ff;
    }
    50% {
        fill: #f36e24;
    }
    100% {
        fill: #0085ff;
    }
}
