body,
html,
#root {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.ymaps-2-1-75-inner-panes {
    border-radius: 5px;
}
.ymaps-2-1-75-map {
    border-radius: 5px;
}

.ReactModalPortal {
    z-index: 2000;
}
.ReactModal__Overlay {
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    color: white;
    font-family: 'Open Sans';
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.75) !important;
}
.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}
.ReactModal__Content {
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: center;
    border: none !important;
    background: transparent !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    padding: 0px !important;
}

h1,
h2,
h3 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
}
